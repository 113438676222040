import React from "react";

import { Heading } from "@molecules";
import { Button, Text } from "@atoms";

const SectionHeading = ({
  heading,
  descriptor,
  button,
  variant,
  size,
  descriptorSize,
  idx,
  hasHero,
}) => {
  const hasButton = button?.url ? "h3" : "h2";
  return (
    <section className="relative z-10 mt-20 sm:-mb-5">
      <Heading
        heading={heading}
        description={descriptor}
        size={!hasHero && idx === 0 ? "h1" : size || hasButton}
        textContainerSize={variant}
        descriptorSize={descriptorSize}
      />
      {button?.url && (
        <div className="mt-8 flex justify-center">
          <Button to={button?.url} color="purple" size="md">
            <Text>{button?.text}</Text>
          </Button>
        </div>
      )}
    </section>
  );
};

export default SectionHeading;
